// ======================================================================
// Mnerva | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

import Tobi from '@rqrauhvmra/tobi'

const tobi = new Tobi({
  captions: false,
  zoom: false
})

var Sticky = require('sticky-js');

var sticky = new Sticky('.split-long .text');
